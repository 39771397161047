import React from 'react'

const DownloadLink = ({ href, filename }) => (
  <div>
    <a href={href} download={filename}>
      {'Click here to download your file'}
    </a>
    <p>
      {`Please close this window once you've downloaded your file`}
    </p>
  </div>
)

export default DownloadLink
