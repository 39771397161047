
import './App.css';
import Form from './components/form'

function App(props) {

  return (
    <div>
        <div className="container">
          <div className="py-4">
            <svg style={{width:'200px'}} viewBox="0 0 157 32" xmlns="http://www.w3.org/2000/svg"><path d="M5 28.4c1.5 1.4 3.7 2.3 6.7 2.3a8 8 0 0 0 5.7-2.2 7 7 0 0 0 2-5c0-3.6-1.6-5.4-4.9-8.5-2.6-2.5-5.4-4.8-5.4-8.6 0-3.7 2.9-6.4 7-6.4 2.5 0 3.7.9 5 2l.2.2L22.6.3h.5l.5 7.4-.5.1a18.9 18.9 0 0 0-3.4-5.2 5.2 5.2 0 0 0-3.8-1.5 4 4 0 0 0-3 1.2c-.7.7-1 1.6-1 2.7 0 2.9 1.6 4.3 4.4 6.9 3 2.6 6.5 5.7 6.5 10.2 0 4.7-3.8 9.9-11.9 9.9C3.4 32 0 27.8 0 23.7 0 19.6 3 17 6.9 17c2.4 0 4 1.2 4 3.1 0 1.2-1 2-2 2-.9 0-1.7-.7-1.7-1.7 0-.8.4-1.6 1.8-1.8h.2V18H9c-.7-.3-1.1-.3-2-.3-1.1 0-2.2.5-3 1.4A6 6 0 0 0 3 22.9c0 2.2.7 4.2 2.1 5.5zm26.4-.7l-4.1.8-.2.7h11.5l.2-.7-3.8-.8L40 4l4 .8 2.9 7.3.4-.1.6-8.5H28.3L26 12h.4l5.4-7.2 4.6-.8-5 23.7zm27.9 0l3.4.7-.2.8H51.7l.2-.7 3.7-.8 2.1-9.7L53.4 5l-2.5-.9.2-.7h9.7l-.1.7-3.5 1 3.6 12L69 5l-2.5-.8.2-.7h7.6l-.2.7-3 .7-9.7 12.6-2.1 10.2zm33.9-7.2l-5.7 7.1-8.6.9 5-23.6 3.7-.7.2-.7H77.1l-.1.7 3.3.7-4.9 22.8-3.6.8-.2.7H91l2.6-8.6h-.4zm10.7-16h-2.6c-4.7 0-7.3 2-7.5 4.5v.3h.7v-.1c.6-.6 1.1-.7 1.6-.7.9 0 1.6.8 1.6 1.6 0 1-.8 1.8-1.9 1.8-1.2 0-2.5-.8-2.6-3 0-1.9.7-3.2 2-4 1.3-1 3.3-1.4 6-1.4h10.2l-.2.7-3.7.7-4.9 22.8 3.5.7-.2.8H95.1l.1-.7 3.8-.8 5-23.1zm30.6 7.5h.4l5.5-7.2 4.5-.8-5 23.7-4.1.8-.1.7h11.5l.1-.7-3.8-.8 5-23.7 4.1.8 2.8 7.3.4-.1.6-8.5h-19.6l-2.3 8.5zm-21 16.4c1.4 1.4 3.6 2.3 6.6 2.3a8 8 0 0 0 5.7-2.2 7 7 0 0 0 2-5c0-3.6-1.6-5.4-4.9-8.5-2.6-2.5-5.4-4.8-5.4-8.6 0-3.7 2.9-6.4 6.9-6.4 2.6 0 3.8.9 5.1 2l.2.2L131 .3h.5l.5 7.4-.5.1a18.9 18.9 0 0 0-3.4-5.2 5.2 5.2 0 0 0-3.8-1.5 4 4 0 0 0-3 1.2c-.7.7-1 1.6-1 2.7 0 2.9 1.6 4.3 4.4 6.9 3 2.6 6.5 5.7 6.5 10.2 0 4.7-3.8 9.9-12 9.9-7.4 0-10.8-4.2-10.8-8.3 0-4.1 3-6.8 6.9-6.8 2.4 0 4 1.2 4 3.1 0 1.2-1 2-2 2-.9 0-1.7-.7-1.7-1.7 0-.8.4-1.6 1.8-1.8h.2V18h-.2c-.7-.3-1.1-.3-2-.3-1.1 0-2.2.5-3 1.4a6 6 0 0 0-1.1 3.8c0 2.2.7 4.2 2.1 5.5z"></path></svg>
          </div>

					<div className="container">
						<h1>Secure File Download</h1>
						<p>Access your data by entering the information below</p>
						<Form endpoint={"https://iris.stylist.co.uk/v1/reports/download"} />
					</div>

						<footer class="page-footer font-small blue">
							<div class="footer-copyright text-center py-3">
								<span>Powered by <a href="https://www.stylist.co.uk" target="_blank" className="text-decoration-none" rel="noreferrer">Stylist Cloud Services</a> &copy;2021+</span>
							</div>
						</footer>
        </div>
    </div>
  );
}

export default App;
