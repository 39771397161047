/* eslint-disable no-undef*/
import React, { Component } from 'react'

import DownloadLink from '../donwload-link'

class Form extends Component {
  state = {
    payloadEmail: null,
    payloadMobile: null,
    payloadVerificationCode: null,
    payloadDsk: null,
    payloadFileID: null,
    isWinnersReport: false,
    dpaTicked: false,
    errorField: null,
    errorMessage: null,
    isLoading: false,
    downloadHref: null,
    downloadFilename: null
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const dsk = urlParams.get('dsk')
    const fileID = urlParams.get('file_id')
    const winners = urlParams.get('winners')

    if (!dsk || !fileID) {
      document.location.replace('https://www.stylist.co.uk')
      return
    }

    this.setState({
      payloadDsk: dsk,
      payloadFileID: fileID,
      isWinnersReport: Boolean(winners)
    })
  }

  handleChange = event => {
    if (event.target.name === 'dpa') {
      const dpaCurrentState = this.state.dpaTicked
      this.setState({ dpaTicked: !dpaCurrentState })
      return
    }

    this.setState({
      [`payload${event.target.id}`]: event.target.value
    })
  }

  isFormValid = () => {
    const trivialEmailValidator = /\S+@\S+\.\S+/
    if (!trivialEmailValidator.test(this.state.payloadEmail)) {
      this.setState({
        errorField: 'email',
        errorMessage: 'Invalid email address'
      })
      return false
    }

    const mobileValidator = /^0[0-9]{10}$/
    if (!mobileValidator.test(this.state.payloadMobile)) {
      this.setState({
        errorField: 'mobile',
        errorMessage: 'Invalid mobile number'
      })
      return false
    }

    if (!this.state.payloadVerificationCode) {
      this.setState({
        errorField: 'verificationCode',
        errorMessage: 'Invalid verification code'
      })
      return false
    }

    if (!this.state.dpaTicked) {
      this.setState({
        errorField: 'dpa',
        errorMessage: 'You need to accept DPA terms and conditions'
      })
      return false
    }

    this.setState({ errorField: null, errorMessage: null, isLoading: true })
    return true
  }

  handleFormSubmit = e => {
    e.preventDefault()

    if (!this.isFormValid()) return false

    fetch(this.props.endpoint, {
      method: 'POST',
      body: JSON.stringify({
        email: this.state.payloadEmail,
        mobile: this.state.payloadMobile,
        verificationCode: this.state.payloadVerificationCode,
        fileId: this.state.payloadFileID,
        dsk: this.state.payloadDsk
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/octet-stream'
      }
    })
      .then(response => {
        if (!response.ok) {
          if (
            response.headers.get('Content-Type').indexOf('application/json') >
            -1
          ) {
            response.json().then(console.log)
          }
          throw new Error('Unable to download file.')
        }

        return response.blob()
      })

      .then(file => {
        const today = new Date()
        const yyyy = today.getFullYear()
        let dd = today.getDate()
        let mm = today.getMonth() + 1

        if (dd < 10) dd = '0' + dd
        if (mm < 10) mm = '0' + mm

        const filename = `Report${
          this.state.isWinnersReport ? '-winners-' : '-optins'
        }${yyyy}-${mm}-${dd}.zip`
        const href = window.URL.createObjectURL(file)

        this.setState({
          downloadHref: href,
          downloadFilename: filename,
          isLoading: false
        })
      })

      .catch(error => {
        this.setState({
          errorField: 'generic',
          errorMessage: error.message,
          isLoading: false
        })
      })
  }

  render() {
    const error = {
      field: this.state.errorField,
      message: this.state.errorMessage
    }

    return (
      <form onSubmit={this.handleFormSubmit} >
        <div className="mb-3">
          <input
            type="text"
            name="Email"
            id="Email"
            placeholder="Your email address"
            onChange={this.handleChange}
						className="form-control"
          />
          {error.field === 'email' && (
            <span>{error.message}</span>
          )}
        </div>

        <div className="mb-3">
          <input
            type="text"
            name="Mobile"
            id="Mobile"
            placeholder="Your mobile number"
            onChange={this.handleChange}
						className="form-control"
          />
          {error.field === 'mobile' && (
            <span>{error.message}</span>
          )}
        </div>

        <div className="mb-3">
          <input
            type="text"
            name="VerificationCode"
            id="VerificationCode"
            placeholder="Your verification code"
            onChange={this.handleChange}
						className="form-control"
          />
          {error.field === 'verificationCode' && (
            <span>{error.message}</span>
          )}
        </div>

        <div className="mb-3">
          <div>
            <input
              type="checkbox"
              name="dpa"
              id="dpa"
              onChange={this.handleChange}
            />
            <label
              htmlFor="dpa"
							className="form-label"
              css={`
                text-align: left;
                margin-left: 0.5rem;
              `}
            >
              {
                'I confirm that any data received from The Stylist Group will be securely stored and handled in accordance with the General Data Protection Regulations.'
              }
            </label>
          </div>
          {error.field === 'dpa' && (
            <span>{error.message}</span>
          )}
        </div>

        <div>
          <button type="submit" class="btn btn-primary">
            {'Get the download link'}
          </button>
        </div>

        {error.field === 'generic' && (
          <span>{error.message}</span>
        )}
        {this.state.downloadHref && (
						<DownloadLink
							filename={this.state.downloadFilename}
							href={this.state.downloadHref}
						/>
        )}
      </form>
    )
  }
}

export default Form
